import React from 'react'
import BlockContent from '@sanity/block-content-to-react'
import Img from 'gatsby-image'
import { getFluidGatsbyImage } from 'gatsby-source-sanity'

import styles from './portable-text.module.css'

import clientConfig from '../../client-config'

const Block = (props) => {
  // Fall back to default handling
  return <div>{BlockContent.defaultSerializers.types.block(props)}</div>
}

const Image = ({ node }) => {
  if (!(node && node.image && node.image.asset && node.image.asset._id)) return null

  let width
  if (node.width === 'partial') {
    width = '640px'
  } else if (node.width === 'extended') {
    width = '960px'
  } else {
    width = '100%'
  }
  let height, color
  if (node.fit === 'contain') {
    height = '600px'
    color = node.color ? node.color.hex : 'inherhit'
  } else {
    height = 'auto'
    color = 'inherit'
  }

  const fluidProps = getFluidGatsbyImage(node.image.asset._id, {}, clientConfig.sanity)
  return (
    <figure className={styles.image} style={{ maxWidth: width, height, backgroundColor: color }}>
      <Img
        fluid={fluidProps}
        alt={node.alt}
        objectFit={node.fit}
        style={{ height, backgroundColor: color }}
        imgStyle={{ objectFit: node.fit }}
      />
      {node.caption ? <figcaption>{node.caption}</figcaption> : null}
    </figure>
  )
}

const Iframe = ({ node }) => {
  if (!(node && node.url)) return null

  let width
  if (node.width === 'partial') {
    width = '640px'
  } else if (node.width === 'extended') {
    width = '960px'
  } else {
    width = '100%'
  }
  const height = '600px'
  const color = node.color ? node.color.hex : 'inherhit'

  return (
    <div className={styles.video} style={{ maxWidth: width, height, backgroundColor: color }}>
      <iframe src={node.url} frameBorder={0} title={"Video"}/>
    </div>
  )
}

const colorText = (props) => {
  return <span style={{ color: props.mark.hex }}>{props.children}</span>
}

const serializers = {
  types: {
    authorReference: ({ node }) => <span>{node.author.name}</span>,
    block: Block,
    bodyBlockImage: Image,
    bodyBlockIframe: Iframe,
    undefined: () => <div></div>,
  },
  marks: {
    color: colorText,
  },
}
const PortableText = ({ blocks }) => (
  <BlockContent
    ignoreUnknownTypes
    blocks={blocks}
    className={styles.root}
    serializers={serializers}
    {...clientConfig.sanity}
  />
)

export default PortableText
