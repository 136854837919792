import React from 'react'
import { graphql } from 'gatsby'
/* import { format, distanceInWords, differenceInDays } from 'date-fns' */

import Container from '../components/container'
import GraphQLErrorList from '../components/graphql-error-list'
import SEO from '../components/seo'
import Layout from '../containers/layout'
import WorkFeature from '../components/work-feature'
import PortableText from '../components/portable-text'
/* import AuthorList from '../components/author-list' */

import styles from './work.module.css'

export const query = graphql`
  query PostTemplateQuery($id: String!) {
    post: sanityPost(id: { eq: $id }) {
      id
      publishedAt
      categories {
        _id
        title
      }
      mainImage {
        ...SanityImage
        alt
      }
      isJournalEntry
      showCategories
      title
      slug {
        current
      }
      postAtRoot
      excerpt
      headerBgColor {
        hex
      }
      headerLinkColor {
        hex
      }
      headerTitleColor {
        hex
      }
      documentColor {
        hex
      }
      headerIframe
      forceFullWidth
      headerImage {
        image {
          asset {
            _id
          }
        }
        width
        alt
      }
      headerLinks {
        url
        title
      }
      rawBody: _rawBody(resolveReferences: { maxDepth: 5 })
      authors {
        _key
        author {
          image {
            crop {
              _key
              _type
              top
              bottom
              left
              right
            }
            hotspot {
              _key
              _type
              x
              y
              height
              width
            }
            asset {
              _id
            }
          }
          name
        }
      }
    }
  }
`

const WorkTemplate = (props) => {
  const { data, errors } = props
  const post = data && data.post

  return (
    <Layout>
      {errors && <SEO title="GraphQL Error" />}
      {post && (
        <SEO title={post.title || 'Untitled'} description={post.excerpt} image={post.mainImage} />
      )}

      {errors && (
        <Container>
          <GraphQLErrorList errors={errors} />
        </Container>
      )}

      {post && (
        <article
          className={styles.root}
          style={{ backgroundColor: post.documentColor ? post.documentColor.hex : '#fff' }}
        >
          <div>
            <WorkFeature
              title={post.title}
              subtitle={post.excerpt}
              backgroundColor={post.headerBgColor.hex}
              color={post.headerTitleColor.hex}
              links={post.headerLinks}
              linkColor={post.headerLinkColor}
              forceFullWidth={post.forceFullWidth}
              featureVisual={
                post.headerImage && post.headerImage.image ? post.headerImage : post.headerIframe
              }
              isLink={false}
            />
          </div>
          <div className={styles.mainContent}>
            <div>{post.rawBody && <PortableText blocks={post.rawBody} />}</div>

            <div className={styles.metaContent}>
              {post.categories && post.showCategories && (
                <ul className={styles.categories}>
                  {post.categories.map((category) => (
                    <li
                      key={category._id}
                      style={{
                        backgroundColor: post.headerBgColor.hex,
                        color: post.headerTitleColor.hex,
                      }}
                    >
                      {category.title}
                    </li>
                  ))}
                </ul>
              )}
              {/* <hr style={{ margin: '25px 0 30px', border: '1px solid #B2B2B2' }} />
              <div className={styles.authorsWrapper}>
                {post.authors && <AuthorList items={post.authors} />}
                {post.publishedAt && (
                  <div className={styles.date}>
                    {differenceInDays(new Date(post.publishedAt), new Date()) > 3
                      ? distanceInWords(new Date(post.publishedAt), new Date())
                      : format(new Date(post.publishedAt), 'MMMM, YYYY')}
                  </div>
                )}
              </div> */}
            </div>
            <div className={styles.grid} />
          </div>
        </article>
      )}
    </Layout>
  )
}

export default WorkTemplate
